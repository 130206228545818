import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Typography from './typography'

const Expandable = styled.button`
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 0.9375rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #e2e2e2;
  padding: 1.375rem 0rem;
  background: none;

  &:after {
    content: '\\02795'; /* Unicode character for "plus" sign (+) */
    font-size: 0.8125rem;
    color: white;
    float: right;
    margin-left: 0.3125rem;
  }

  .content {
    display: none;
    overflow: hidden;
  }

  .preview {
    display: block;
  }

  ${props =>
    props.expanded &&
    css`
      &:after {
        content: '\\2796'; /* Unicode character for "minus" sign (-) */
      }
      .content {
        display: block;
      }
      .preview {
        display: none !important;
      }
    `}
`

const ExpandButton = ({ content }) => {
  const [expanded, setExpanded] = useState(false)
  const toggleExpanded = () => setExpanded(!expanded)

  return (
    <Expandable type="button" onClick={toggleExpanded} expanded={expanded}>
      <div>
        <Typography
          textType="label-medium"
          style={{ display: 'block', margin: '0rem', marginBottom: '0.5rem' }}
          fontWeight={500}
          component="h4"
        >
          {content.header}
        </Typography>
        <Typography
          textType="paragraph-small"
          colorType="core.content.secondary"
          className="preview"
          style={{ display: 'block' }}
          component="p"
        >
          {content.preview}
        </Typography>
        <div className="content">
          <Typography
            textType="paragraph-small"
            colorType="core.content.secondary"
            style={{ display: 'block' }}
            component="p"
          >
            {content.content}
          </Typography>
        </div>
      </div>
    </Expandable>
  )
}

const Collapsible = ({ contents = [] }) => {
  return (
    <div>
      {contents.map(content => (
        <ExpandButton content={content} key={content.header} />
      ))}
    </div>
  )
}

export default Collapsible
