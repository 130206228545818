import React from 'react'
import styled from 'styled-components'

import Nav from '../components/nav'
import Collapsible from '../components/collapsible'
import Footer from '../components/footer'
import useHotjarScript from '../utils/hooks/useHotjarScript'

const FAQs = () => {
  useHotjarScript()
  return (
    <>
      <Nav theme="light" />
      <Container>
        <Collapsible
          contents={[
            {
              header: 'How can I upgrade to a Advanced Team Plan?',
              preview:
                'You can change your plan at any time, whether that’s an upgrade or a downgrade..',
              content: (
                <>
                  You can change your plan at any time, whether that’s an
                  upgrade or a downgrade. Your changes will be prorated and
                  reflected on your following month’s invoice. To change your
                  plan, send us an email at{' '}
                  <a href="mailto:support@pulse.so" style={{ color: 'black' }}>
                    support@pulse.so
                  </a>{' '}
                  and we'll take care of it for you. And if you’re on our
                  Enterprise, you can find details around switching your plan in
                  your contract. Reach out to your customer success manager if
                  you have any questions.
                </>
              ),
            },
            {
              header: 'Can you send me an invoice?',
              preview:
                "If you're on the Advanced Plan for Teams you can reach out at..",
              content: (
                <>
                  If you're on the Advanced Plan for Teams you can reach out at{' '}
                  <a href="mailto:support@pulse.so" style={{ color: 'black' }}>
                    support@pulse.so
                  </a>{' '}
                  with additional details. If you're on the Advanced Plan for
                  Enterprises, reach out to your Customer Success Manager.
                </>
              ),
            },
            {
              header: 'How do I cancel my paid plan?',
              preview:
                'On our Advanced Plan for Team, you’ll be billed monthly and can cancel at any time..',
              content: (
                <>
                  On our Advanced Plan for Team, you’ll be billed monthly and
                  can cancel at any time. There’s no long-term agreement, and
                  you can delete your Pulse account at any time. While we're
                  working to automate this, for the time being you can contact
                  at{' '}
                  <a href="mailto:support@pulse.so" style={{ color: 'black' }}>
                    support@pulse.so
                  </a>{' '}
                  for termination and renewal details. If you're and reach out
                  to your account rep for more info. On our Advanced Plan for
                  Enterprises you can reach out directly to your Customer
                  Success Manager.
                </>
              ),
            },
            {
              header: 'Refund Policy',
              preview:
                'If you’re not 100% satisfied with the purchase, or it’s not what you were expecting..',
              content: (
                <>
                  {' '}
                  If you’re not 100% satisfied with the purchase, or it’s not
                  what you were expecting, just reach out at{' '}
                  <a href="mailto:support@pulse.so" style={{ color: 'black' }}>
                    support@pulse.so
                  </a>{' '}
                  within 30 days, and you’ll get a full refund. No questions
                  asked.
                </>
              ),
            },
            {
              header: 'Fair Billing Policy',
              preview:
                'Most enterprise software pricing is designed to charge you per user regardless of how many people on ..',
              content: (
                <>
                  {' '}
                  Most enterprise software pricing is designed to charge you per
                  user regardless of how many people on your team are actively
                  using the product. That's why you only get billed for what you
                  use, so you don’t pay for the members who aren’t using Pulse.{' '}
                </>
              ),
            },
          ]}
        />
      </Container>
      <Footer />
    </>
  )
}

const Container = styled.main`
  padding-top: 5rem;
  padding-bottom: 5rem;
  width: 43.75rem;
  max-width: 92.5vw;
  margin: auto;
`

export default FAQs
